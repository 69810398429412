/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bool, string, object } from 'prop-types';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import { getBrandName } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import isVideoFormat from '../../../../helpers/isVideoFormat';

import PersonalizedWidget from './PersonalizedWidget';
import GuestUserGreetingContainer from '../../GraphqlCommonComponents/PersonalizedUserGreeting/GuestUserGreeting/GuestUserGreetingContainer';
import ComponentErrorBoundary from '../Common/ComponentErrorBoundary/ComponentErrorBoundary';
import HeroSingle from '../../../ContentTypeComponents/HeroSingle/HeroSingle';
import PersonalizedHero from '../Common/PersonalizedHero';
import HeroSingleResponsive from '../../../ContentTypeComponents/HeroSingle/HeroSingleResponsive';
import GenericSkeleton from '../../GraphqlSkeletonComponents/GenericSkeleton';

const useStyles = makeStyles(() => ({
    foodRoot: {
        width: '98%',
        margin: 'auto',
    },
    flowerRoot: {
        width: '100%',
        margin: 'auto',
        overflow: 'hidden',
    },
}));

const PersonalizedWidgetContainer = ({
    data: {
        content_zones,
        skeleton_height,
        skeleton_width,
    },
    presentationFamily,
    isCollectionPage,
}) => {
    const [showSkeleton, setShowSkeleton] = useState(true);
    const classes = useStyles();
    const brand = useSelector(getBrandName) || '1800flowers';
    const interactionName = `${brand} - Get Campaign - Personalized Widget`;

    const targeting = {
        pageType: 'home',
    };
    if (content_zones?.length && content_zones?.[0]) {
        targeting.contentZones = content_zones;
    }

    const {
        loading, error, data, variables,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'PersonalizedContent',
        interactionName,
        targeting,
    });

    if (loading || variables?.skip) {
        return showSkeleton && <GenericSkeleton height={skeleton_height} width={skeleton_width} />;
    }

    if (error || variables.eidError) {
        mbpLogger.logError({
            function: `PersonalizedWidgetContainer - interactionName = ${interactionName}`,
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    const { campaign, content } = data?.contentSF || {};
    const sfContentType = campaign?.campaignResponses?.[0]?.payload?.cmsContentType;
    const contentTypeValueObject = {};
    const contentTypesToIncludeRefs = ['generic_banner'];

    if (sfContentType === 'gift_finder') contentTypeValueObject['gift_finder_reference'] = [content];
    if (contentTypesToIncludeRefs.includes(sfContentType)) contentTypeValueObject['reference'] = [content];

    const block = {
        [sfContentType]: sfContentType === 'gift_finder' || contentTypesToIncludeRefs.includes(sfContentType) ? contentTypeValueObject : content,
    };
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];

    const sfCampaignResponse = data?.contentSF;
    const sfCMSData = sfCampaignResponse?.content;
    const components = {
        GuestUserGreetingContainer: () => (
            <GuestUserGreetingContainer
                cmsData={sfCMSData}
                salesForceCampaignResponse={sfCampaignResponse}
                isCollectionPage={isCollectionPage}
            />
        ),

        HeroSingle: () => {
            switch (blockKey) {
                case 'hero_single': {
                    const { image = null, video = null } = blockObj?.reference?.[0];
                    return (
                        <ComponentErrorBoundary module="HeroSingle" function="HomePageBlock">
                            {!isVideoFormat(image?.content_type || video?.desktop?.mp4?.content_type || video?.desktop?.webm?.content_type)
                                ? <PersonalizedHero data={blockObj} salesforceResponse={sfCampaignResponse} setShowSkeleton={setShowSkeleton} />
                                : <HeroSingle data={blockObj} salesforceResponse={sfCampaignResponse} setShowSkeleton={setShowSkeleton} />}
                        </ComponentErrorBoundary>
                    );
                }
                case 'hero_single_responsive': {
                    return (
                        <ComponentErrorBoundary module="HeroSingleResponsive" function="HomePageBlock">
                            <HeroSingleResponsive data={blockObj} salesforceResponse={sfCampaignResponse} setShowSkeleton={setShowSkeleton} />
                        </ComponentErrorBoundary>
                    );
                }
                default:
                    return null;
            }
        },
    };

    return (
        <div className={`${presentationFamily === 'food' ? classes.foodRoot : classes.flowerRoot}`} data-testid={`${presentationFamily}_rendered`}>
            <PersonalizedWidget components={components} data={data} />
        </div>
    );
};

PersonalizedWidgetContainer.propTypes = {
    data: object,
    presentationFamily: string,
    isCollectionPage: bool,
};

PersonalizedWidgetContainer.defaultProps = {
    data: {},
    presentationFamily: 'flower',
    isCollectionPage: false,
};

export default (PersonalizedWidgetContainer);
